import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import "./Login.css";
import { withRouter } from "react-router-dom";
import { firebase } from "../../firebase";

const users = ["test3@test.com"];
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Playshifu
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login(props) {
  const classes = useStyles();
  var [isLogin, setIsLogin] = useState(true);
  var [email, setEmail] = useState("");
  var [password, setPassword] = useState("");
  var [passwordConfirm, setPasswordConfirm] = useState("");
  var [open, setOpen] = useState(false);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        console.log("USER", user.email);
        props.history.push("/home");
      }
    });
  }, []);
  function emailInput(e) {
    e.preventDefault();
    setEmail(e.target.value);
  }

  function passwordInput(e) {
    e.preventDefault();
    setPassword(e.target.value);
  }

  function passwordConfirmInput(e) {
    e.preventDefault();
    setPasswordConfirm(e.target.value);
  }

  function signInClicked(e) {
    e.preventDefault();
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        console.log("RESPONSE USER SIGNED IN", res);
        props.history.push("/home");
      })
      .catch(function (error) {
        var errorCode = error.code;
        var errorMessage = error.message;
        alert(errorMessage);
      });
  }
  function signUpClicked(e) {
    console.log("SIGN UP CLICKEd");
    e.preventDefault();
    if (password === passwordConfirm) {
      console.log("EQUAL", firebase);
      // props.history.push("/sidebar");
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then((user) => {
          // Signed in
          // ...
          console.log("RESPONSE USER CREATED", user);
          alert("User Created Successfully. Login to continue");
          props.history.push("/home");
        })
        .catch((error) => {
          var errorCode = error.code;
          var errorMessage = error.message;
          // ..
        });
      // firebase
      //   .auth()
      //   .createUserWithEmailAndPassword(email, password)
      //   .then((res) => {})
      //   .catch(function (error) {
      //     // Handle Errors here.
      //     var errorCode = error.code;
      //     var errorMessage = error.message;
      //     // ...
      //   });
    } else {
      alert("Password and Confirm Password doesn't match");
    }
  }

  const handleClick = () => {
    setOpen(true);
  };
  const signupPage = (e) => {
    e.preventDefault();
    setIsLogin(false);
  };
  const signinPage = (e) => {
    e.preventDefault();
    setIsLogin(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <span>{isLogin === true ? "Signin" : "SignUp"}</span> to Playshifu Troubleshooting Dashboard
        </Typography>
        {isLogin === true ? (
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => emailInput(e)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => passwordInput(e)}
              autoComplete="current-password"
            />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => signInClicked(e)}>
              SignIn
            </Button>
            <a
              style={{ backgroundColor: "none", textDecoration: "underline", color: "blue" }}
              onClick={(e) => signupPage(e)}>
              Don't Have an Account? SignUp Here
            </a>
          </form>
        ) : (
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(e) => emailInput(e)}
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => passwordInput(e)}
              autoComplete="current-password"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Confirm Password"
              type="password"
              id="password"
              value={passwordConfirm}
              onChange={(e) => passwordConfirmInput(e)}
              autoComplete="current-password"
            />
            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={(e) => signUpClicked(e)}>
              SignUp
            </Button>
            <a
              style={{ backgroundColor: "none", textDecoration: "underline", color: "blue" }}
              onClick={(e) => signinPage(e)}>
              Already Have an Account? SignIn Here
            </a>
          </form>
        )}
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}
export default withRouter(Login);
