import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import { getWebOrdersOpen } from "../../../actions/getDataAction";

import {
  updateTicketStatus,
  updateEmailStatus,
} from "../../../actions/orderActions";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Popup from "../../../Components/Popup";
import PopupRakuten from "../../../Components/PopupRakuten";
import Loading from "../../../Components/Loading";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import { saveAs } from "file-saver";

import "./style.css";
import JSZip from "jszip";
const _ = require("lodash");
const moment = require("moment");
var axios = require("axios");
var JSZipUtils = require("jszip-utils");

const theme = createMuiTheme({
  typography: {
    fontSize: 11,
  },
});

class DownloadImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      number: "",
    };
  }

  handleChange = (event) => {
    this.setState({ value: event.target.value });
  };

  handleNumber = (event) => {
    this.setState({ number: event.target.value });
  };

  download = () => {
    console.log(this.state.value, this.state.number);
    alert("Please keep calm, okay??");

    // var config = {
    //   method: "get",
    //   url: `http://localhost:5000/mws-sales-data/us-central1/downloadImagesTS?searchText=${this.state.value}&number=${this.state.number}`,
    //   headers: {
    //     "Content-Type": "application/x-www-form-urlencoded",
    //     "Access-Control-Allow-Origin": "*",
    //   },
    // };

    // axios(config)
    //   .then(function (response) {
    //     console.log("GET WEB PRDERS", response.data);
    //     var count = 0;
    //     response.data.forEach((image) => {
    //       var zip = new JSZip();
    //       // const url = window.URL.createObjectURL(new Blob([image[0].data]));
    //       const base64String = btoa(
    //         String.fromCharCode(...new Uint8Array(image[0].data))
    //       );
    //       const link = document.createElement("a");
    //       link.href = "data:image/png;base64," + base64String;
    //       link.download = "Image.png"; //File name Here
    //       response.data.pop()
    //       setInterval(() => {
    //         link.click(); //Downloaded file
    //       }, 300);

    //       if (response.data.length === 0) {
    //         clearInterval();
    //       }
    //       //   JSZipUtils.getBinaryContent(link.href, function (err, data) {
    //       //     if (err) {
    //       //       throw err; // or handle the error
    //       //     }
    //       //     zip.file(image[1].split(".com/")[1], data, { binary: true });
    //       //     count++;
    //       //     console.log(count, response.data.length)
    //       //     if (count == response.data.length) {
    //       //       zip.generateAsync({ type: "blob" }).then(function (content) {
    //       //         saveAs(content, "Images.zip");
    //       //       });
    //       //     }
    //       //   });
    //     });
    //   })
    //   .catch((error) => {
    //     console.log("ERROR", error);
    //   });
  };

  render() {
    return (
      <div>
        <form>
          <div>
            <TextField
              required
              id="outlined-required"
              label="Required"
              defaultValue=""
              variant="outlined"
              style={{ marginRight: "10px" }}
              value={this.state.value}
              onChange={this.handleChange}
            />
            <TextField
              id="outlined-number"
              label="Number"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
              style={{ marginRight: "10px" }}
              value={this.state.number}
              onChange={this.handleNumber}
            />
            <Button
              variant="contained"
              color="primary"
              style={{ margin: 10 }}
              onClick={this.download}
            >
              Download
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

export default withRouter(DownloadImages);
