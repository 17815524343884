import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import { getWebOrders } from "../../../actions/getDataAction";
import {
  updateTicketStatus,
  updateEmailStatus,
} from "../../../actions/orderActions";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Popup from "../../../Components/Popup";
import PopupRakuten from "../../../Components/PopupRakuten";
import Loading from "../../../Components/Loading";
import Button from "@material-ui/core/Button";
import "./style.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

const _ = require("lodash");
const moment = require("moment");
const admin = ["anand_solanki@playshifu.com", "aman.c@playshifu.com", "maazulla@playshifu.com", "rahul_ohri@playshifu.com", "pratyush@playshifu.com", "vivek@playshifu.com", "venkat_reddy@playshifu.com","shridhar_pujari@playshifu.com","mohammed_uzaid@playshifu.com"]

const theme = createMuiTheme({
  typography: {
    fontSize: 11,
  },
});

class DataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
      searchText: "",
      open: false,
      isAccountAvailable: true,
      requestSubmitted: false,
      popupDataImages: [[]],
      popupDataFovVertical: "",
      popupDataFovHorizontal: "",
      popupDataUiFPS: "",
      popupDataDetectionFPS: "",
      userEmail: "",
      selectedTicketId: "",
      popupCannedResponse: [],
      openCannedResponse: false,
      ticketStatusValue: "Close",
      emailStatusValue: "Yes",
      raw : false,
      userEmail: "",
      rawImagesCol: true,
    };
    
  }

  componentWillMount() {
    this.setUser();
    this.getRawImagesSettingOption();
    this.setState({ table_data: this.props.webOrders });
    console.log("PROPS", this.props);
  }

  getRawImagesSettingOption = () => {
    const getRawImageSettingAPI =
      "https://us-central1-mws-sales-data.cloudfunctions.net/getRawImagesSettingTroubleshootDash";
    // setLoading(true);
    axios({
      method: "GET",
      url: getRawImageSettingAPI,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        // setLoading(false);
        if (response.status === 200 && response.data.status === "success") {
          const status = response.data.displayRawImages;
          this.setState({rawImagesCol : status.toLowerCase() === "true"});
          console.log("success, fetched setting , ", status);
        } 
      })
      .catch((error) => {
        // setLoading(false);
        console.log("error - ", error);
        alert("error while fetching settings!");
      });
  };
  componentWillReceiveProps(nextProps) {
    console.log("NEXT PROPS", nextProps);

    this.setState({ table_data: nextProps.webOrders, requestSubmitted: false });
  }

  imagesButtonClicked = (e, value, tableMeta, raw) => {
    this.setState({ open: true });
    console.log(value, "VAL", tableMeta.tableData);
    this.setState({
      popupDataImages: tableMeta.tableData[tableMeta.rowIndex][12],
      popupDataFovHorizontal: tableMeta.tableData[tableMeta.rowIndex][15],
      popupDataFovVertical: tableMeta.tableData[tableMeta.rowIndex][14],
      popupDataUiFPS: tableMeta.tableData[tableMeta.rowIndex][16],
      popupDataDetectionFPS: tableMeta.tableData[tableMeta.rowIndex][17],
      raw:raw,
    });

    setTimeout(() => {
      console.log(tableMeta.tableData[tableMeta.rowIndex], "DATA DETECTION", this.state.popupDataDetectionFPS);
    }, 2000);
  };

  cannedResponseClicked = (e, value, tableMeta) => {
    this.setState({ openCannedResponse: true });
    console.log(
      value,
      "CANNED RESPONSE CLICKED ROW",
      tableMeta.tableData[tableMeta.rowIndex]
    );
    this.setState({
      popupCannedResponse: tableMeta.tableData[tableMeta.rowIndex][13],
      selectedTicketId: tableMeta.tableData[tableMeta.rowIndex][0],
    });
  };
  setUser = () => {
    console.log("SET USER CALLED");
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
      } else {
        console.log("USER EMAIL", user.email);
        this.setState({ userEmail: user.email });
      }
    });
  };
  handleClose = () => {
    this.setState({ open: false });
  };

  handleCloseCannedResponse = () => {
    this.setState({ openCannedResponse: false });
  };
  onChangeText = (e) => {
    this.setState({ searchText: e.target.value });
  };
  handleChangeEmailStatus = (e, tableMeta) => {
    e.preventDefault();
    this.setState({ emailStatusValue: e.target.value });
    this.props.updateEmailStatus(
      tableMeta.tableData[tableMeta.rowIndex][0],
      e.target.value === "No" ? 0 : 1
    );
  };
  handleChangeTicketStatus = (e, tableMeta) => {
    e.preventDefault();
    this.setState({ ticketStatusValue: e.target.value });
    this.props.updateTicketStatus(
      tableMeta.tableData[tableMeta.rowIndex][0],
      e.target.value === "No" ? 0 : 1
    );
  };
  submitText = () => {
    this.props.getWebOrders(this.state.searchText);
    this.setState({ requestSubmitted: true });
  };
  render() {
    const columns = [
      {
        name: "Ticket Id",
        label: "Ticket Id",
      },
      {
        name: "Date",
        label: "Date",
      },
      {
        name: "Email",
        label: "Email",
        options: {
          responsive: "scroll",
          customBodyRender: (value) => {
            return (
              <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                {value}
              </div>
            );
          },
        },
      },
      {
        name: "Version",
        label: "Version",
      },
      {
        name: "deviceModel",
        label: "deviceModel",
      },
      {
        name: "distinctID",
        label: "distinctID",
        options: {
          responsive: "scroll",
          customBodyRender: (value) => {
            return (
              <div>
                <Tooltip title={value}>
                  <div>{value.substring(0, 10)}</div>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: "kitToTS",
        label: "kitToTS",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {value}
                <br />
                {/* <Button
                  onClick={(e) => this.imagesButtonClicked(e, value, tableMeta)}
                  variant="contained"
                  color="primary">
                  Images
                </Button> */}
              </div>
            );
          },
        },
      },
      {
        name: "OS",
        label: "OS",
        options: {
          customBodyRender: (value) => {
            return (
              <div>
                <Tooltip title={value}>
                  <div>{value.substring(0, 10)}</div>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: "additionalUserMessage",
        label: "additionalUserMessage",
        options: {
          customBodyRender: (value) => {
            return (
              <div>
                <Tooltip title={value}>
                  <div>{value.substring(0, 10)}</div>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: "Ticket Status",
        label: "Ticket Status",
      },
      {
        name: "TS EnteredFrom",
        label: "TS EnteredFrom",
        options: {
          responsive: "scroll",
          customBodyRender: (value) => {
            return (
              <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                {value}
              </div>
            );
          },
        },
      },
      {
        name: "Best Resolution",
        label: "Best Resolution",
      },
      {
        name: "Images",
        label: "Images",
        options: {
          display : admin.includes(this.state.userEmail) ? true : false,
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Button
                  onClick={(e) => this.imagesButtonClicked(e, value, tableMeta, true)}
                  variant="contained"
                  style={{ width: "100%" }}
                  color="primary"
                >
                  RAW
                </Button>
              </div>
            );
          },
        },
      },
      {
        name: "Canned Response",
        label: "Canned Response",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Button
                  onClick={(e) =>
                    this.cannedResponseClicked(e, value, tableMeta)
                  }
                  variant="contained"
                  className="rounded-3xl"
                  color="primary"
                >
                  GET
                </Button>
              </div>
            );
          },
        },
      },
      {
        name: "Mixpanel Data",
        label: "Mixpanel Data",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <a
                  href={`https://vto0fs9z4c.execute-api.ap-south-1.amazonaws.com/production/get-mixpanel-json?email=${
                    tableMeta.tableData[tableMeta.rowIndex][2]
                  }&distinct_id=${
                    tableMeta.tableData[tableMeta.rowIndex][5]
                  }&time=${moment().tz("America/Los_Angeles")}`}
                  target="_blank"
                >
                  <Button variant="contained" color="primary">
                    GET
                  </Button>
                </a>
              </div>
            );
          },
        },
      },
      {
        name: "Email Status",
        label: "Email Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={this.state.emailStatusValue}
                    onChange={(e) => this.handleChangeEmailStatus(e, tableMeta)}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio style={{height:"15px"}} />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio style={{height:"15px"}} />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            );
          },
        },
      },
      {
        name: "Ticket Status",
        label: "Ticket Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    value={this.state.ticketStatusValue}
                    onChange={(e) => this.handleChangeTicketStatus(e.tableMeta)}
                  >
                    <FormControlLabel
                      value="Open"
                      control={<Radio style={{height:"15px"}} />}
                      label="Open"
                    />
                    <FormControlLabel
                      value="Close"
                      control={<Radio style={{height:"15px"}} />}
                      label="Close"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            );
          },
        },
      },
      {
        name: "Ticket Status",
        label: "Ticket Status",
        options: {
          display: false,
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: false,
      responsive: "scrollFullHeightFullWidth",
      resizableColumns: true,
    };
    return (
      <div>
        <div style={{ marginBottom: "20px" }}>
          {" "}
          <TextField
            autoFocus
            onChange={(e) => this.onChangeText(e)}
            margin="dense"
            id="email"
            label="Email"
            type="email"
            value={this.state.searchText}
          />
          <Button
            onClick={(e) => this.submitText(e)}
            style={{ marginLeft: "1rem", marginTop: "1rem" }}
            color="primary"
          >
            Submit
          </Button>
        </div>
        {this.state.requestSubmitted === true ? (
          <Loading />
        ) : (
          <MuiThemeProvider theme={theme}>
            <div>
              <Popup
                open={this.state.open}
                data={this.state.popupDataImages}
                FovDataVertical={this.state.popupDataFovVertical}
                FovDataHorizontal={this.state.popupDataFovHorizontal}
                raw = {this.state.raw}
                uiFPS={this.state.popupDataUiFPS}
                detectionFPS={this.state.popupDataDetectionFPS}
                handleClose={this.handleClose}
                onCancelOrder={this.onCancelOrder}
                userEmail={this.state.userEmail}
                disableOTP={!this.state.rawImagesCol}
              />
              <PopupRakuten
                open={this.state.openCannedResponse}
                handleClose={this.handleCloseCannedResponse}
                cannedResponseData={this.state.popupCannedResponse}
                ticket_id={this.state.selectedTicketId}
              />

              <MUIDataTable
                title={"OPEN TICKETS"}
                data={this.state.table_data}
                columns={columns}
                options={options}
              />
            </div>
          </MuiThemeProvider>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  webOrders: state.webOrders.webOrders,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getWebOrders: (searchText) => {
      getWebOrders(dispatch, searchText);
    },
    updateTicketStatus: (ticket_id, ticketStatus) => {
      updateTicketStatus(dispatch, ticket_id, ticketStatus);
    },
    updateEmailStatus: (ticket_id, emailStatus) => {
      updateEmailStatus(dispatch, ticket_id, emailStatus);
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DataTable));
