import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { set } from "lodash";
import { firebase } from "../firebase";
import { cancelOrder, cancelItem, updateUserDetails, updateShipmentLink } from "../actions/orderActions";
import { connect } from "react-redux";
import { render } from "@testing-library/react";
const db = firebase.firestore();
const _ = require("lodash");
const axios = require("axios");

export default class PopupImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fieldUpdated: false,
      quantity: 1,
      token: "",
      value: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("USE EFECT CALLED");
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  onChangeQuantity = (e) => {
    e.preventDefault();
    this.setState({ quantity: e.target.value });
  };
  onUpdateDetails = (e) => {
    e.preventDefault();
    this.setState({ open: false });
  };
  handleChange = (e) => {
    e.preventDefault();
    console.log("RADIO E", e);
    this.setState({ radioValue: e.target.value });
    let clicked_response = _.find(this.props.cannedResponseData, { title: e.target.value });
    console.log("CLICKED CANNED RESPONSE", clicked_response);
  };
  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
            Canned Response Email Preview
          </DialogTitle>
          <DialogContent>
            <img
              style={{ maxHeight: "620px", marginLeft: "20%", marginRight: "30%" }}
              src={`https://shifu-website-test.s3.ap-south-1.amazonaws.com/${this.props.image_id}.png`}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
