import React, { Component } from "react";
import DataTable from "./Components/DataTable";
export default class Dashboard extends Component {
  render() {
    return (
      <div>
        <DataTable />
      </div>
    );
  }
}
