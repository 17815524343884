import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/Dashboard";
import OpenTickets from "./Pages/Dashboard/OpenTickets";
import Sidebar from "./Pages/Sidebar/Sidebar";

const THEME = createMuiTheme({
  typography: {
    fontFamily: "'Nunito', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
});
function App() {
  return (
    <div className="App">
      {/* <header className="App-header"></header> */}
      <MuiThemeProvider theme={THEME}>
        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={() => <Redirect to="/login" />} />
            <Route exact path="/home" render={() => <Redirect to="/home/open-tickets" />} />
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/home">
              <Sidebar />
            </Route>
          </Switch>
        </BrowserRouter>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
