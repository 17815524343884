import { GET_WEB_ORDERS, GET_GA_DATA, NO_OF_TICKETS, GET_BEFORE_30 } from "../actions/types";

const initialState = {
  webOrders: [],
  openTickets: [],
  openBefore30: []
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_WEB_ORDERS:
      return {
        ...state,
        webOrders: action.payload,
      };
    case GET_BEFORE_30:
        return {
          ...state,
          openBefore30: action.payload,
        };
    case GET_GA_DATA:
      return {
        ...state,
        openTickets: action.payload,
      };
    case NO_OF_TICKETS:
      return {
        ...state,
        noOfTickets: action.payload,
      };
    default:
      return state;
  }
}
