import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import { withRouter } from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import OpenTickets from "../Dashboard/OpenTickets";
import DownloadImages from "../Dashboard/DownloadImages";
import SolvedTickets from "../Dashboard/SolvedTickets";
import DataTableBefore30 from "../Dashboard/Components/DataTableBefore30";
import { firebase } from "../../firebase";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./style.css";
import Settings from "../Dashboard/Settings";
const drawerWidth = 240;
const settingsTabAccess = [
  "anand_solanki@playshifu.com",
  "aman.c@playshifu.com",
  "vivek@playshifu.com",
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarShiftClose: {
    width: `calc(100% - ${0}px)`,
    marginLeft: "0px",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

function Sidebar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [mobile, setMobile] = React.useState(false);
  const [user, setUser] = React.useState({});

  const [currentPage, setCurrentPage] = React.useState("Closed Tickets");
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    console.log("HANDLE DRAWE XCLOSED");
    setOpen(false);
  };
  const logoutClicked = () => {
    firebase
      .auth()
      .signOut()
      .then(function () {
        console.log("Sign-out successful.");
        props.history.push("/login");
      })
      .catch(function (error) {
        console.log("An error happened whsile signing out");
      });
    // setOpen(false);
  };

  const settingClicked = () => {
    props.history.push("/home/settings");
    setCurrentPage("settings");
    // setOpen(false);
  };
  const dashIconClicked = (text) => {
    // setOpen(false);
    console.log("dash icon clicked", text);
    if (text === "Open Tickets") props.history.push("/home/open-tickets");
    else if (text === "Before 30") props.history.push("/home/before-30");
    else if (text === "Closed Tickets")
      props.history.push("/home/closed-tickets");
    else if (text === "Solved Tickets")
      props.history.push("/home/solved-tickets");
    else if (text === "Download") props.history.push("/home/download-images");
    setCurrentPage(text);
    window.location.reload();
  };

  useEffect(() => {
    console.log("props", props);
    if (window.innerWidth < 600) {
      console.log("YES SMALL1", open, mobile);

      setTimeout(() => {
        setMobile(true);
        handleDrawerClose();
        console.log("YES SMALL2", open, mobile);
      }, 100);
    }
    firebase.auth().onAuthStateChanged(function (user) {
      if (!user) {
        // console.log("USER", user.email);
        props.history.push("/login");
      } else {
        setUser(user);
      }
    });
    // handleDrawerOpen();
    return;
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
          [classes.appBarShiftClose]: open === false,
        })}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}>
            <MenuIcon />
          </IconButton>

          {mobile === true ? (
            <IconButton style={{}}>
              <img
                style={{ width: "100px" }}
                src={require("../../assets/shifu-logo.png")}
              />
            </IconButton>
          ) : (
            <div />
          )}

          {props.history.location.pathname === "/home/open-tickets" ? (
            <div className="text-xl">Open Tickets</div>
          ) : null}
          {props.history.location.pathname === "/home/before-30" ? (
            <div className="text-xl">Before 30</div>
          ) : null}
          {props.history.location.pathname === "/home/closed-tickets" ? (
            <div className="text-xl">Close Tickets</div>
          ) : null}
          {props.history.location.pathname === "/home/solved-tickets" ? (
            <div className="text-xl">Solved Tickets</div>
          ) : null}
          {props.history.location.pathname === "/home/download-images" ? (
            <div className="text-xl">Download Images</div>
          ) : null}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          {mobile === false ? (
            <IconButton style={{}}>
              <img
                style={{ width: "100px" }}
                src={require("../../assets/shifu-logo.png")}
              />
            </IconButton>
          ) : (
            <div />
          )}
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            "Open Tickets",
            "Before 30",
            "Closed Tickets",
            "Solved Tickets",
            "Download",
          ].map((text, index) => (
            <ListItem button key={text}>
              <button
                className="btn-no-outline"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => dashIconClicked(text)}>
                <IconButton>
                  {index === 0 ? (
                    <img
                      style={{ width: "30px" }}
                      src={require("../../assets/dashboard-icon.svg")}
                    />
                  ) : null}
                  {index === 1 ? (
                    <img
                      style={{ width: "30px" }}
                      src={require("../../assets/dashboard-icon.svg")}
                    />
                  ) : null}
                  {index === 2 ? (
                    <img
                      style={{ width: "30px" }}
                      src={require("../../assets/profile-icon.svg")}
                    />
                  ) : null}
                  {index === 3 ? (
                    <img
                      style={{ width: "30px" }}
                      src={require("../../assets/solved-tickets.svg")}
                    />
                  ) : null}
                  {index === 4 ? (
                    <img
                      style={{ width: "30px" }}
                      src={require("../../assets/image.svg")}
                    />
                  ) : null}
                </IconButton>
                <p
                  style={{
                    fontSize: "20px",
                    fontFamily: "'Nunito', sans-serif",
                  }}>
                  {text}
                </p>{" "}
              </button>
            </ListItem>
          ))}
          {settingsTabAccess.includes(user.email) ? (
            <ListItem button key="Settings">
              <button
                className="btn-no-outline"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                }}
                onClick={() => settingClicked()}>
                <IconButton>
                  <img
                    style={{ width: "30px" }}
                    src={require("../../assets/settings-icon.svg")}
                  />
                </IconButton>

                <p
                  style={{
                    fontSize: "20px",
                    fontFamily: "'Nunito', sans-serif",
                  }}>
                  Settings
                </p>
              </button>
            </ListItem>
          ) : (
            ""
          )}
          <ListItem button key="Logout">
            <button
              className="btn-no-outline"
              style={{
                backgroundColor: "transparent",
                border: "none",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
              }}
              onClick={() => logoutClicked()}>
              <IconButton>
                <img
                  style={{ width: "30px" }}
                  src={require("../../assets/logout-icon.svg")}
                />
              </IconButton>

              <p
                style={{
                  fontSize: "20px",
                  fontFamily: "'Nunito', sans-serif",
                }}>
                Logout
              </p>
            </button>
          </ListItem>
        </List>
      </Drawer>

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}>
        <div className={classes.drawerHeader} />
        <Route path="/home/closed-tickets">
          <Dashboard />
        </Route>
        <Route path="/home/open-tickets">
          <OpenTickets />
        </Route>
        <Route path="/home/solved-tickets">
          <SolvedTickets />
        </Route>
        <Route path="/home/download-images">
          <DownloadImages />
        </Route>
        <Route path="/home/before-30">
          <DataTableBefore30 />
        </Route>
        <Route path="/home/settings">
          <Settings />
        </Route>
      </main>
    </div>
  );
}

export default withRouter(Sidebar);
