import MUIDataTable from "mui-datatables";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firebase } from "../../../firebase";
import { getWebOrdersBefore30 } from "../../../actions/getDataAction";
import { updateTicketStatus, updateEmailStatus } from "../../../actions/orderActions";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import Popup from "../../../Components/Popup";
import PopupRakuten from "../../../Components/PopupRakuten";
import Loading from "../../../Components/Loading";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

import "./style.css";
const _ = require("lodash");
const moment = require("moment");

const theme = createMuiTheme({
  typography: {
    fontSize: 11,
  },
});

class DataTableBefore30 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      table_data: [],
      searchText: "",
      open: false,
      openCannedResponse: false,
      isAccountAvailable: true,
      ticketStatusValue: "Open",
      popupDataImages: [[]],
      popupCannedResponse: [],
      popupDataFovVertical: "",
      popupDataFovHorizontal: "",
      popupDataUiFPS: "",
      popupDataDetectionFPS: "",
      selectedTicketId: "",
      userEmail: "",
      emailStatusValue: "No",
      display1: "",
    };
  }

  componentWillMount() {
    this.setUser();
    this.props.getWebOrdersBefore30();
    this.setState({ table_data: this.props.webOrders });
    console.log("PROPS", this.props);
  }

  componentWillReceiveProps(nextProps) {
    console.log("NEXT PROPS", nextProps);
    // this.props.getWebOrdersOpen();
    this.setState({ table_data: nextProps.webOrders });
  }

  imagesButtonClicked = (e, value, tableMeta) => {
    this.setState({ open: true });

    this.setState({
      popupDataImages: tableMeta.tableData[tableMeta.rowIndex][12],
      popupDataFovHorizontal: tableMeta.tableData[tableMeta.rowIndex][15],
      popupDataFovVertical: tableMeta.tableData[tableMeta.rowIndex][14],
      popupDataUiFPS: tableMeta.tableData[tableMeta.rowIndex][16],
      popupDataDetectionFPS: tableMeta.tableData[tableMeta.rowIndex][17],
    });

    setTimeout(() => {
      console.log(tableMeta.tableData[tableMeta.rowIndex], "DATA DETECTION", this.state.popupDataDetectionFPS);
    }, 2000);
  };
  cannedResponseClicked = (e, value, tableMeta) => {
    this.setState({ openCannedResponse: true });
    console.log(value, "CANNED RESPONSE CLICKED ROW", tableMeta.tableData[tableMeta.rowIndex]);
    this.setState({
      popupCannedResponse: tableMeta.tableData[tableMeta.rowIndex][13],
      selectedTicketId: tableMeta.tableData[tableMeta.rowIndex][0],
    });
  };

  handleChangeEmailStatus = (e, tableMeta) => {
    e.preventDefault();
    console.log(this.state);
    this.setState({ emailStatusValue: e.target.value });
    this.props.updateEmailStatus(tableMeta.tableData[tableMeta.rowIndex][0], e.target.value === "No" ? 0 : 1);
  };
  handleChangeTicketStatus = (e, tableMeta) => {
    e.preventDefault();
    this.setState({ ticketStatusValue: e.target.value });
    this.props.updateTicketStatus(
      tableMeta.tableData[tableMeta.rowIndex][0],
      e.target.value === "Close" ? "close" : "open"
    );
  };

  setUser = () => {
    console.log("SET USER CALLED");
    firebase.auth().onAuthStateChanged((user) => {
      if (!user) {
      } else {
        console.log("USER EMAIL", user.email);
        this.setState({ userEmail: user.email });
      }
    });
  };
  handleClose = () => {
    this.setState({ open: false, display1: "" });
  };

  handleCloseCannedResponse = () => {
    this.setState({ openCannedResponse: false });
  };

  onChangeText = (e) => {
    this.setState({ searchText: e.target.value });
  };

  submitText = () => {
    this.props.getWebOrders(this.state.searchText);
  };
  render() {
    const columns = [
      {
        name: "Ticket Id",
        label: "Ticket Id",
      },
      {
        name: "Date",
        label: "Date",
        options: {
          customBodyRender: (value, tableMeta) => {
            const date = new Date(value);
            const formattedDate = date
              .toLocaleDateString("en-GB", {
                day: "numeric",
                month: "short",
              })
              .replace(/ /g, "-");
            return <div>{formattedDate}</div>;
          },
        },
      },
      {
        name: "Email",
        label: "Email",
        options: {
          responsive: "scroll",
          customBodyRender: (value) => {
            return (
              <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                {value}
              </div>
            );
          },
        },
      },
      {
        name: "Version",
        label: "Version",
      },
      {
        name: "deviceModel",
        label: "deviceModel",
      },
      {
        name: "distinctID",
        label: "distinctID",
        options: {
          responsive: "scroll",
          customBodyRender: (value) => {
            return (
              <div>
                <Tooltip title={value}>
                  <div>{value.substring(0, 10)}</div>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: "kitToTS",
        label: "kitToTS",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                {value}
                <br />
                {/* <Button
                  onClick={(e) => this.imagesButtonClicked(e, value, tableMeta)}
                  variant="contained"
                  color="primary">
                  Images
                </Button> */}
              </div>
            );
          },
        },
      },
      {
        name: "OS",
        label: "OS",
        options: {
          customBodyRender: (value) => {
            return (
              <div>
                <Tooltip title={value}>
                  <div>{value.substring(0, 10)}</div>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: "additionalUserMessage",
        label: "additionalUserMessage",
        options: {
          customBodyRender: (value) => {
            return (
              <div>
                <Tooltip title={value}>
                  <div>{value.substring(0, 10)}</div>
                </Tooltip>
              </div>
            );
          },
        },
      },
      {
        name: "Ticket Status",
        label: "Ticket Status",
      },
      {
        name: "TS EnteredFrom",
        label: "TS EnteredFrom",
        options: {
          responsive: "scroll",
          customBodyRender: (value) => {
            return (
              <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
                {value}
              </div>
            );
          },
        },
      },
      {
        name: "Best Resolution",
        label: "Best Resolution",
      },
      {
        name: "Images",
        label: "Images",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <Button
                  onClick={(e) => this.imagesButtonClicked(e, value, tableMeta)}
                  variant="contained"
                  style={{ width: "100%" }}
                  color="primary"
                >
                  Images
                </Button>
              </div>
            );
          },
        },
      },
      {
        name: "Canned Response",
        label: "Canned Response",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <Button
                onClick={(e) => this.cannedResponseClicked(e, value, tableMeta)}
                variant="contained"
                color="primary"
                style={{ width: "100%" }}
              >
                GET
              </Button>
            );
          },
        },
      },
      {
        name: "Mixpanel Data",
        label: "Mixpanel Data",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <a
                  href={`https://vto0fs9z4c.execute-api.ap-south-1.amazonaws.com/production/get-mixpanel-json?email=${
                    tableMeta.tableData[tableMeta.rowIndex][2]
                  }&distinct_id=${
                    tableMeta.tableData[tableMeta.rowIndex][5]
                  }&time=${moment().tz("America/Los_Angeles")}`}
                  target="_blank"
                >
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                  >
                    GET
                  </Button>
                </a>
              </div>
            );
          },
        },
      },
      {
        name: "Email Status",
        label: "Email Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue={"No"}
                    // value={value}
                    onChange={(e) => this.handleChangeEmailStatus(e, tableMeta)}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio style={{height:"15px"}} />}
                      label="Yes"
                      style={{height:20}}
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio style={{height:"15px"}} />}
                      label="No"
                      style={{height:20}}

                    />
                  </RadioGroup>
                </FormControl>
              </div>
            );
          },
        },
      },
      {
        name: "Ticket Status",
        label: "Ticket Status",
        options: {
          customBodyRender: (value, tableMeta, updateValue) => {
            return (
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    defaultValue={"Open"}
                    // value={value}
                    onChange={(e) =>
                      this.handleChangeTicketStatus(e, tableMeta)
                    }
                  >
                    <FormControlLabel
                      value="Open"
                      control={<Radio />}
                      label="Open"
                      style={{height:20}}
                    />
                    <FormControlLabel
                      value="Close"
                      control={<Radio />}
                      label="Close"
                      style={{height:20}}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            );
          },
        },
      },
      {
        name: "Ticket Status",
        label: "Ticket Status",
        options: {
          display: false,
        },
      },
    ];

    const options = {
      filterType: "checkbox",
      selectableRows: false,
      responsive: "scrollFullHeightFullWidth",
      resizableColumns: true,
    };
    return (
      <div>
        {this.state.table_data.length === 0 && this.props.noOfTickets !== 0 ? (
          <Loading />
        ) : this.props.noOfTickets === 0 && this.state.table_data.length === 0 ? (
          <h1> No Open Tickets</h1>
        ) : (
          <MuiThemeProvider theme={theme}>
            <div>
              <Popup
                open={this.state.open}
                data={this.state.popupDataImages}
                FovDataVertical={this.state.popupDataFovVertical}
                FovDataHorizontal={this.state.popupDataFovHorizontal}
                uiFPS={this.state.popupDataUiFPS}
                detectionFPS={this.state.popupDataDetectionFPS}
                handleClose={this.handleClose}
                onCancelOrder={this.onCancelOrder}
              />
              <PopupRakuten
                open={this.state.openCannedResponse}
                handleClose={this.handleCloseCannedResponse}
                cannedResponseData={this.state.popupCannedResponse}
                ticket_id={this.state.selectedTicketId}
              />

              <MUIDataTable
                title={"BEFORE 30"}
                data={this.state.table_data}
                columns={columns}
                options={options}
              />
            </div>
          </MuiThemeProvider>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  webOrders: state.webOrders.openTickets,
  noOfTickets: state.webOrders.noOfTickets,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // getWebOrdersOpen: () => {
    //   dispatch(getWebOrdersOpen);
    // },
    getWebOrdersBefore30: () => {
        dispatch(getWebOrdersBefore30);
      },
    updateTicketStatus: (ticket_id, ticketStatus) => {
      updateTicketStatus(dispatch, ticket_id, ticketStatus);
    },
    updateEmailStatus: (ticket_id, emailStatus) => {
      updateEmailStatus(dispatch, ticket_id, emailStatus);
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataTableBefore30));
