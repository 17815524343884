import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Checkbox from "@material-ui/core/Checkbox";
import $ from "jquery"
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { firebase } from "../firebase";
import { connect } from "react-redux";
import PopupImage from "./PopupImage";
const _ = require("lodash");

class PopupRakuten extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openImage: false,
      fieldUpdated: false,
      quantity: 1,
      token: "",
      value: "",
      selectedImageId: "",
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log("USE EFECT CALLED", nextProps);
    this.setState({ open: nextProps.open });
  }

  handleClose = () => {
    this.setState({ open: false });
  };
  handleCloseImage = () => {
    this.setState({ openImage: false });
  };

  replyEmail = () => {
    console.log(this.state.selectedImageId, this.props.ticket_id)
    if(this.state.selectedImageId === undefined ||this.state.selectedImageId === "" ){
      alert("You need to select a canned response")
      return;
    }
    $.ajax({
      url: `https://vto0fs9z4c.execute-api.ap-south-1.amazonaws.com/production/plugo-ts-freskdesk-canned`,
      type: "POST",
      crossDomain: true,
      data: JSON.stringify({ ticket_id: this.props.ticket_id, canndResId: this.state.selectedImageId }),
      success: function (data) {
        console.log("data", data);
        if (data.statusCode = 200) {
          alert("Replied Email successfully!");
          window.location.reload();
        } else {
          alert("Error")
        }
      }
    })
  }

  onChangeQuantity = (e) => {
    e.preventDefault();
    this.setState({ quantity: e.target.value });
  };
  onUpdateDetails = (e) => {
    e.preventDefault();
    this.setState({ open: false });
  };
  handleChange = (e) => {
    console.log("RADIO E", e);
    this.setState({ radioValue: e.target.value });
    let clicked_response = _.find(this.props.cannedResponseData, { title: e.target.value });
    console.log("CLICKED CANNED RESPONSE", clicked_response);
    this.setState({ openImage: true, selectedImageId: clicked_response.id });
  };
  render() {
    return (
      <div>
        <Dialog open={this.state.open} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle style={{ marginTop: "2%" }} id="form-dialog-title">
            Canned response
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Ticket ID: {this.props.ticket_id}</DialogContentText>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={this.state.radioValue}
              onChange={this.handleChange}
              row>
              {this.props.cannedResponseData.map((cannedResponse) => {
                return (
                  <FormControlLabel value={cannedResponse.title} control={<Radio />} label={cannedResponse.title} />
                );
              })}
            </RadioGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Close
            </Button>
            <Button 
            onClick={this.replyEmail} 
            color="primary">
              Reply Email
            </Button>
          </DialogActions>
        </Dialog>
        <PopupImage
          open={this.state.openImage}
          handleClose={this.handleCloseImage}
          image_id={this.state.selectedImageId}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  webOrders: state.webOrders.webOrders,
});

export default connect(mapStateToProps, {})(PopupRakuten);
