import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slider from "react-slick";
import { firebase } from "../firebase";
import { connect } from "react-redux";
import axios from "axios";
class Popup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			ordersArray: [],
			imagesArray: [],
			dimArray: [],
			FovDataHorizontal: "",
			FovDataVertical: "",
			open: false,
			fieldUpdated: false,
			name: "",
			email: "",
			address: "",
			paypalId: "",
			shipmentLink: "",
			selectedSku: "",
			display1: "",
			tab: null,
			raw: false,
			imageAccessStatus: "Processed",
			userEmail: null,
			otpInputVal: "",
		};
	}

	componentWillReceiveProps(nextProps) {
		/*console.log(
      "USE EFECT CALLED",
      nextProps.FovDataHorizontal,
      nextProps.data[0].split("_"),
      window.screen.height,
      nextProps.raw
    );*/

		// console.log("nextprops - ", nextProps);
		// _ in id to for [0] length = 5 , length = 3
		// _ not in id length =4 , length = 2
		nextProps.data.map((item) => {
			if (item.length > 0) this.state.dimArray.push(item.split("_").slice(-1)[0]);
		});

		// let authorisedImagesSet;
		if (nextProps.data[0].length > 0 && nextProps.data[0].split("_").length === 4) {
			// console.log(
			//   nextProps.FovDataHorizontal,
			//   nextProps.FovDataVertical,
			//   nextProps.data[0]
			// );

			let imagesArr = nextProps.data;

			if (nextProps.raw === true) {
				imagesArr = imagesArr.map((data) => {
					return {
						image: data,
						status: "processed",
					};
				});
			}
			// if(nextProps.raw === false) {

			// }
			// console.log("images arr - ", imagesArr);
			// const authorisedImagesSet = new Set(nextProps.authorisedImages);
			this.setState({
				imagesArray: imagesArr,
				FovDataVertical: nextProps.FovDataVertical,
				FovDataHorizontal: nextProps.FovDataHorizontal,
				raw: nextProps.raw === true ? true : false,
				userEmail: nextProps.userEmail,
				display1:
					nextProps.data[0].length > 0
						? `V:${nextProps.FovDataVertical.split(",")[0]}, H:${
								nextProps.FovDataHorizontal.split(",")[0]
						  }, R:processed_${nextProps.data[0].split("_").slice(-3)[0]}`
						: "",
			});
		} else {
			// console.log("next props in else - ", nextProps);
			let imagesArr = nextProps.data;

			if (nextProps.raw === true) {
				imagesArr = imagesArr.map((data) => {
					return {
						image: data,
						status: "processed",
					};
				});
			}

			// console.log("images array - ", imagesArr);
			this.setState({
				imagesArray: imagesArr,
				raw: nextProps.raw === true ? true : false,
				FovDataVertical: nextProps.FovDataVertical,
				FovDataHorizontal: nextProps.FovDataHorizontal,
				userEmail: nextProps.userEmail,
				display1:
					nextProps.data[0].length > 0
						? `V:${nextProps.FovDataVertical.split(",")[0]}, H:${
								nextProps.FovDataHorizontal.split(",")[0]
						  }, R:processed_${nextProps.data[0].split("_").slice(-1)[0]}`
						: "",
			});
		}
	}

	requestImageAccess = (imageName) => {
		// console.log(
		//   "image access requested for user - ",
		//   this.state.userEmail,
		//   ", image: ",
		//   imageName
		// );

		// const requestImageAccessAPI =
		//   "http://localhost:5001/mws-sales-data/us-central1/TSDashRawImageRequestAccess";

		const requestImageAccessAPI =
			"https://us-central1-mws-sales-data.cloudfunctions.net/TSDashRawImageRequestAccess";
		const data = {
			email: this.state.userEmail,
			imageName: imageName,
		};
		axios({
			method: "POST",
			url: requestImageAccessAPI,
			data: data,
			headers: { "Content-Type": "application/json" },
		})
			.then((response) => {
				// setLoading(false);
				// console.log("success, response - ", response);
				let tempImageArr = this.state.imagesArray;
				const objIndex = tempImageArr.findIndex((obj) => obj.image.includes(imageName));
				// console.log("found at index ", objIndex);
				tempImageArr[objIndex].status = "AccessRequested";
				// console.log("temp image arr - ", tempImageArr);
				this.setState({
					imagesArray: tempImageArr,
				});
			})
			.catch((err) => {
				// setLoading(false);
				console.log("error in requestImageAccess");
				alert("error in requestImageAccess");
				console.log(err);
			});
	};

	verifyOTP = (imageName) => {
		// console.log(
		//   "image access requested for user - ",
		//   this.state.userEmail,
		//   ", image: ",
		//   imageName,
		//   ", otp: ",
		//   this.state.otpInputVal
		// );

		const verifyOTP_API =
			"https://us-central1-mws-sales-data.cloudfunctions.net/TSDashRawImageValidateAccess";
		// const requestImageAccessAPI =
		//   "http://localhost:5001/mws-sales-data/us-central1/TSDashRawImageValidateAccess";
		const data = {
			email: this.state.userEmail,
			imageName: imageName,
			otp: this.state.otpInputVal,
		};
		axios({
			method: "POST",
			url: verifyOTP_API,
			data: data,
			headers: { "Content-Type": "application/json" },
		})
			.then((response) => {
				// setLoading(false);
				// console.log("success, response - ", response.data);
				if (
					response.data.status === "failed" ||
					response.data.msg.includes("otp verfication failed")
				) {
					alert("OTP verfication failed");
					this.setState({
						otpInputVal: "",
					});
				} else {
					let tempImageArr = this.state.imagesArray;
					const objIndex = tempImageArr.findIndex((obj) => obj.image.includes(imageName));
					// console.log("found at index ", objIndex);
					tempImageArr[objIndex].status = "Verified";
					tempImageArr[objIndex].image =
						"https://plugo-troubleshoot-img.s3.amazonaws.com/" + imageName;
					// console.log("temp image arr - ", tempImageArr);
					console.log("imageName =====", imageName);
					this.setState({
						imagesArray: tempImageArr,
					});
					this.setState({
						otpInputVal: "",
					});
				}
			})
			.catch((err) => {
				// setLoading(false);
				console.log("error in requestImageAccess");
				alert("error in requestImageAccess");
				console.log(err);
				this.setState({
					otpInputVal: "",
				});
			});
	};

	handleClose = () => {
		this.props.handleClose();
		this.setState({ open: false, display1: "" });
	};

	updateInputValue(evt) {
		const val = evt.target.value;
		// ...
		this.setState({
			otpInputVal: val,
		});
	}

	render() {
		const settings1 = {
			customPaging: (i) => {
				return (
					<a className="slick-bottom-track">
						<img src={this.state.dimArray[i]} />
					</a>
				);
			},
			afterChange: (i) => {
				// console.log(this.state.tab, this.state.dimArray[i]);
				this.setState({ tab: i });
				// {
				//   this.state.FovDataHorizontal &&
				//   this.state.imagesArray[i].split("_").length === 4
				//     ? this.setState({
				//         display1: `V:${this.state.FovDataVertical.split(",")[i]}, H:${
				//           this.state.FovDataHorizontal.split(",")[i]
				//         }, R:processed_${
				//           this.state.imagesArray[i].split("_").slice(-3)[0]
				//         }`,
				//       })
				//     : this.state.FovDataHorizontal &&
				//       this.state.imagesArray[i].split("_").length > 4
				//     ? this.setState({
				//         display1:
				//           `V:${this.state.FovDataVertical.split(",")[i]}, H:${
				//             this.state.FovDataHorizontal.split(",")[i]
				//           },` +
				//           `R:processed_` +
				//           `${this.state.imagesArray[i]}`.split("_").slice(-1)[0],
				//       })
				//     : this.setState({
				//         display1: `V:${this.state.FovDataVertical.split(",")[i]}, H:${
				//           this.state.FovDataHorizontal.split(",")[i]
				//         }, R:${this.state.imagesArray[i].split("_").slice(-1)[0]}`,
				//       });
				// }
			},
			dots: true,
			dotsClass: "slick-dots slick-thumb",
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
		};
		const settings = {
			customPaging: (i) => {
				let imageElem = "";
				if (this.state.raw === true) {
					if (this.props.disableOTP) {
						const imageParts = this.state.imagesArray[i].image.split("/");
						const imageName = imageParts[imageParts.length - 1];
						console.log("imageParts ==========", imageParts, imageName);
						imageElem = "https://plugo-troubleshoot-img.s3.amazonaws.com/" + imageName;
					} else {
						imageElem = this.state.imagesArray[i].image;
					}
				} else {
					imageElem = this.state.imagesArray[i];
				}

				return (
					<a className="slick-bottom-track">
						<img src={imageElem} />
					</a>
				);
			},
			afterChange: (i) => {
				// console.log(this.state.tab, this.state.imagesArray[i].image);

				const imageElem =
					this.state.raw === true
						? this.state.imagesArray[i].image
						: this.state.imagesArray[i];
				this.setState({ tab: i });
				{
					this.state.FovDataHorizontal && imageElem.split("_").length === 4
						? this.setState({
								display1: `V:${this.state.FovDataVertical.split(",")[i]}, H:${
									this.state.FovDataHorizontal.split(",")[i]
								}, R:processed_${imageElem.split("_").slice(-3)[0]}`,
						  })
						: this.state.FovDataHorizontal && imageElem.split("_").length > 4
						? this.setState({
								display1:
									`V:${this.state.FovDataVertical.split(",")[i]}, H:${
										this.state.FovDataHorizontal.split(",")[i]
									},` +
									`R:processed_` +
									`${imageElem}`.split("_").slice(-1)[0],
						  })
						: this.setState({
								display1: `V:${this.state.FovDataVertical.split(",")[i]}, H:${
									this.state.FovDataHorizontal.split(",")[i]
								}, R:${imageElem.split("_").slice(-1)[0]}`,
						  });
				}
			},
			dots: true,
			dotsClass: "slick-dots slick-thumb",
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: true,
		};

		return (
			<div>
				{this.state.raw === true ? (
					<Dialog
						open={this.props.open}
						onClose={this.props.handleClose}
						maxWidth="lg"
						aria-labelledby="form-dialog-title"
					>
						<DialogTitle id="form-dialog-title">Images</DialogTitle>
						<DialogContent>
							{" "}
							<h4 style={{ marginLeft: "40%", marginRight: "40%", width: "i0%" }}>
								DetectionFPS:{this.props.detectionFPS},UiFPS:{this.props.uiFPS}
							</h4>
							<Slider {...settings}>
								{this.state.imagesArray.map((imageElem) => {
									console.log("here each image elem - ", imageElem);
									const imageParts = imageElem.image.split("/");
									const imageName = imageParts[imageParts.length - 1];
									return (
										<div>
											<span>Image Name - {imageName}</span>
											{!this.props.disableOTP ? (
												<>
													<br />
													{imageElem.status === "processed" ? (
														<button
															style={{
																border: "1px solid",
																borderRadius: "6px",
																fontWeight: "700",
																background: "white",
																color: "blue",
																padding: "8px",
																marginTop: "10px",
															}}
															onClick={() =>
																this.requestImageAccess(imageName)
															}
														>
															Request Raw Access
														</button>
													) : imageElem.status === "AccessRequested" ? (
														<>
															<input
																style={{
																	border: "1px solid grey",
																	padding: "6px",
																	borderRadius: "7px",
																	marginTop: "10px",
																	marginBottom: "10px",
																}}
																name="otp-input"
																id="otp-input"
																value={this.state.otpInputVal}
																onChange={(evt) =>
																	this.updateInputValue(evt)
																}
																placeholder="enter otp"
															/>
															<br />
															<button
																style={{
																	border: "1px solid",
																	padding: "6px",
																	borderRadius: "6px",
																	color: "blue",
																	fontWeight: "600",
																}}
																onClick={() =>
																	this.verifyOTP(imageName)
																}
															>
																Verify
															</button>
														</>
													) : (
														""
													)}
													<img
														alt="img"
														style={{
															marginLeft: "auto",
															marginRight: "auto",
															maxHeight: "400px",
														}}
														src={imageElem.image}
													/>
												</>
											) : (
												<img
													alt="img"
													style={{
														marginLeft: "auto",
														marginRight: "auto",
														maxHeight: "400px",
													}}
													src={
														"https://plugo-troubleshoot-img.s3.amazonaws.com/" +
														imageName
													}
												/>
											)}
										</div>
									);
								})}
							</Slider>
							{window.screen.height > 864 ? (
								<h4
									style={{
										textAlign: "center",
										position: "absolute",
										top: "76%",
										left: "38%",
									}}
								>
									{this.state.display1}
								</h4>
							) : (
								<h4
									style={{
										textAlign: "center",
										position: "absolute",
										top: "74%",
										left: "38%",
									}}
								>
									{this.state.display1}
								</h4>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								Close
							</Button>
						</DialogActions>
					</Dialog>
				) : (
					<Dialog
						open={this.props.open}
						onClose={this.props.handleClose}
						maxWidth="lg"
						aria-labelledby="form-dialog-title"
					>
						<DialogTitle id="form-dialog-title">Images</DialogTitle>
						<DialogContent>
							{" "}
							<h4 style={{ marginLeft: "40%", marginRight: "40%", width: "i0%" }}>
								DetectionFPS:{this.props.detectionFPS},UiFPS:{this.props.uiFPS}
							</h4>
							{window.screen.height > 864 ? (
								<h4
									style={{
										textAlign: "center",
										position: "absolute",
										top: "76%",
										left: "38%",
									}}
								>
									{this.state.display1}
								</h4>
							) : (
								<h4
									style={{
										textAlign: "center",
										position: "absolute",
										top: "74%",
										left: "38%",
									}}
								>
									{this.state.display1}
								</h4>
							)}
						</DialogContent>
						<DialogActions>
							<Button onClick={this.handleClose} color="primary">
								Close
							</Button>
						</DialogActions>
					</Dialog>
				)}
			</div>
		);
	}
}
const mapStateToProps = (state) => ({
	webOrders: state.webOrders.webOrders,
});

export default connect(mapStateToProps, {})(Popup);
