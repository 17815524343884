import React, { useState } from "react";
import Switch from "react-switch";
import SyncLoader from "react-spinners/PulseLoader";
// import Switch from "@mui/material/Switch";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import axios from "axios";
import { css } from "@emotion/react";
import { useEffect } from "react";
const Settings = () => {
  const [switchCheckStatus, setSwitchCheckStatus] = useState();
  const [loading, setLoading] = useState(false);
  const handleRawImagesSwitchChange = (switchStatus) => {
    setSwitchCheckStatus(switchStatus);
    const setRawImageSettingAPI =
      "https://us-central1-mws-sales-data.cloudfunctions.net/setRawImagesSettingTroubleshootDash";
    setLoading(true);
    axios({
      method: "POST",
      url: setRawImageSettingAPI,
      data: { settingValue: switchStatus },
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 200 && response.data.status === "success") {
          console.log("success, updated the setting");
        } else {
          setSwitchCheckStatus(!switchStatus);
          alert("error while changing settings!");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error - ", error);
        setSwitchCheckStatus(!switchStatus);
        alert("error while changing settings!");
      });
  };

  const getRawImagesSettingOption = () => {
    const getRawImageSettingAPI =
      "https://us-central1-mws-sales-data.cloudfunctions.net/getRawImagesSettingTroubleshootDash";
    setLoading(true);
    axios({
      method: "GET",
      url: getRawImageSettingAPI,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setLoading(false);
        if (response.status === 200 && response.data.status === "success") {
          const status = response.data.displayRawImages;
          setSwitchCheckStatus(status.toLowerCase() === "true");
          console.log("success, fetched setting , ", status);
        } else {
          alert("error while changing settings!");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error - ", error);
        alert("error while changing settings!");
      });
  };
  useEffect(() => {
    getRawImagesSettingOption();
  }, []);

  const override = css`
    position: absolute;
    left: 48%;
    top: 40%;
    z-index: 999;
  `;

  return (
    <div>
      <span
        style={{
          fontWeight: "600",
          fontSize: "28px",
          marginBottom: "100px",
          color: "#1c1c1cd6",
        }}>
        Settings
      </span>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "50px",
        }}>
        <span
          style={{
            fontSize: "20px",
            fontWeight: "500",
            color: "#1c1c1cd6",
            marginRight: "15px",
          }}>
          Require OTP based Authentication (Raw Images)
        </span>
        <label htmlFor="material-switch">
          <Switch
            onChange={handleRawImagesSwitchChange}
            checked={switchCheckStatus}
            onColor="#72C8CC"
            onHandleColor="#fffff"
            uncheckedIcon={false}
            checkedIcon={false}
            className="react-switch"
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            // height={20}
            // width={40}
            // handleDiameter={4}
          />
        </label>
      </div>
      <SyncLoader
        color="#bb6bd9"
        id={"panel-loader"}
        loading={loading}
        css={override}
        size={18}
        margin={8}
      />
    </div>
  );
};

export default Settings;
