export const updateEmailStatus = (dispatch, ticket_id, emailStatus) => {
  console.log("ACTION ORDER", ticket_id, emailStatus);
  fetch("https://vto0fs9z4c.execute-api.ap-south-1.amazonaws.com/production/plugo-ts-update-email-status", {
    method: "POST",
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    //   "Access-Control-Allow-Origin": "*",
    // },
    body: JSON.stringify({ emailStatusUpdae: true, ticket_id: ticket_id, emailStatus: emailStatus }),
  })
    .then((result) => {
      result.json();
    })
    .then((response) => {
      console.log("LAMBDA API response", response);
    })
    .catch((err) => console.log("LAMBDA API ERROR", err));
};
export const updateTicketStatus = (dispatch, ticket_id, ticketStatus) => {
  console.log("ACTION ORDER", ticket_id, ticketStatus);
  fetch("https://vto0fs9z4c.execute-api.ap-south-1.amazonaws.com/production/plugo-ts-update-email-status", {
    method: "POST",
    // headers: {
    //   "Content-Type": "application/x-www-form-urlencoded",
    //   "Access-Control-Allow-Origin": "*",
    // },
    body: JSON.stringify({ ticketStatusUpdae: true, ticket_id: ticket_id, ticketStatus: ticketStatus }),
  })
    .then((result) => {
      result.json();
    })
    .then((response) => {
      console.log("LAMBDA API response", response);
    })
    .catch((err) => console.log("LAMBDA API ERROR", err));
};
