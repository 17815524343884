import { GET_WEB_ORDERS, GET_GA_DATA, NO_OF_TICKETS, GET_BEFORE_30 } from "./types";
import { firebase } from "../firebase";
const _ = require("lodash");
const moment = require("moment-timezone");
var axios = require("axios");
var mysql = require("mysql");

const mysqlConn = mysql.createConnection({
  host: "ap-south1-mysql-instance1.cswystw6zsdo.ap-south-1.rds.amazonaws.com",
  user: "admin",
  password: "shifudev2016",
  database: "ecomm_dev",
  multipleStatements: true,
});

const db = firebase.firestore();
export const getWebOrders = async (dispatch, searchText) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTickets?searchText=${searchText}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then(function (response) {
      let table_data = [];
      console.log("GET WEB PRDERS", response.data);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA", table_data);

      dispatch({
        type: GET_WEB_ORDERS,
        payload: table_data,
      });

    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersCopy(dispatch, searchText);
    });
};
export const getWebOrdersOpen = async (dispatch) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTicketsOpen`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then((response) => {
      let table_data = [];
      console.log("GET WEB ORDERS", response.data.totalOpenTickets);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA OPEN", table_data);

      dispatch({
        type: GET_GA_DATA,
        payload: table_data,
      });
      dispatch({
        type: NO_OF_TICKETS,
        payload: response.data.totalOpenTickets,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersOpenCopy(dispatch);
    });
};
export const getWebOrdersBefore30 = async (dispatch) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTicketsBeforeOpen`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then((response) => {
      let table_data = [];
      console.log("GET WEB ORDERS", response.data.totalOpenTickets);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA OPEN", table_data);

      dispatch({
        type: GET_GA_DATA,
        payload: table_data,
      });
      dispatch({
        type: NO_OF_TICKETS,
        payload: response.data.totalOpenTickets,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersBefore30Copy(dispatch);
    });
};
export const getWebOrdersSolved = async (dispatch) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTicketsSolved`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then((response) => {
      let table_data = [];
      console.log("GET WEB ORDERS", response.data.totalOpenTickets);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA OPEN", table_data);

      dispatch({
        type: GET_GA_DATA,
        payload: table_data,
      });
      dispatch({
        type: NO_OF_TICKETS,
        payload: response.data.totalOpenTickets,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersSolvedCopy(dispatch);
    });
};

// This function gets called when the main function fails , this is because of CF bug
const getWebOrdersCopy = async (dispatch, searchText) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTickets?searchText=${searchText}`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then(function (response) {
      let table_data = [];
      console.log("GET WEB PRDERS", response.data);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA", table_data);

      dispatch({
        type: GET_WEB_ORDERS,
        payload: table_data,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersCopy(dispatch, searchText);
    });
};

const getWebOrdersOpenCopy = async (dispatch) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTicketsOpen`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then((response) => {
      let table_data = [];
      console.log("GET WEB ORDERS", response.data.totalOpenTickets);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA OPEN", table_data);

      dispatch({
        type: GET_GA_DATA,
        payload: table_data,
      });
      dispatch({
        type: NO_OF_TICKETS,
        payload: response.data.totalOpenTickets,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersOpenCopy(dispatch);
    });
};
const getWebOrdersSolvedCopy = async (dispatch) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTicketsSolved`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then((response) => {
      let table_data = [];
      console.log("GET WEB ORDERS", response.data.totalOpenTickets);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA OPEN", table_data);

      dispatch({
        type: GET_GA_DATA,
        payload: table_data,
      });
      dispatch({
        type: NO_OF_TICKETS,
        payload: response.data.totalOpenTickets,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersSolvedCopy(dispatch);
    });
};
const getWebOrdersBefore30Copy = async (dispatch) => {
  var config = {
    method: "get",
    url: `https://us-central1-mws-sales-data.cloudfunctions.net/troubleshootTicketsBeforeOpen`,
    headers: { "Content-Type": "application/x-www-form-urlencoded", "Access-Control-Allow-Origin": "*" },
  };

  axios(config)
    .then((response) => {
      let table_data = [];
      console.log("GET WEB ORDERS", response.data.totalOpenTickets);
      response.data.data.forEach((element) => {
        table_data.push([
          element.ticket_id,
          element.date,
          element.email,
          element.appVersion,
          element.deviceModel,
          element.distinctID,
          element.kitToTroubleshoot,
          element.operatingSystem,
          element.additionalUserMessage,
          element.ticketStatusOpen === true ? "Open" : "Closed",
          element.troubleshootingEnteredFrom,
          element.bestResolution ? element.bestResolution : 0 ,
          element.otherImgLink,
          element.getCannedRespons,
          element.verticalFOV,
          element.horizontalFOV,
          element.uiFPS,
          element.detectionFPS,
        ]);
      });

      console.log("TABLE DATA OPEN", table_data);

      dispatch({
        type: GET_BEFORE_30,
        payload: table_data,
      });
      dispatch({
        type: NO_OF_TICKETS,
        payload: response.data.totalOpenTickets,
      });
    })
    .catch((error) => {
      console.log("ERROR", error);
      getWebOrdersBefore30Copy(dispatch);
    });
};
